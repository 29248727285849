import { condofyProtected } from './instance'

export const getBankConsentUrl = ({ condominium, bankCode }) => {
  return condofyProtected.get(
    `v1/condominiums/${condominium.id}/${bankCode}/consent-url`
  )
}

export const getBankConsentStatus = ({ condominium, bankCode  }) => {
  return condofyProtected.get(`v1/condominiums/${condominium.id}/${bankCode}/consent`)
}

export const cancelBankConsent = ({ condominium, bankCode  }) => {
  return condofyProtected.delete(
    `v1/condominiums/${condominium.id}/${bankCode}/consent`
  )
}

export const createBankConsent = ({ condominium, authorizationCode, bankCode }) => {
  return condofyProtected.post(
    `v1/condominiums/${condominium.id}/${bankCode}/consent`,
    { authorizationCode }
  )
}
