import React from 'react'
import Checkmark from 'Assets/icons/checkmark.svg'


const premiumPlanOffers = [
  'Tudo do plano Starter',
  'Assessoria em Assembleias',
  'Administração Completa',
  'Prestação de contas',
  'Controle de vencimentos ',
  'Folha de Pagamento e DP',
  'Emissão de boletos',
  'Assessoria Jurídica',
  'Pagamentos a fornecedores',
  'Cobrança de Inadimplentes',
  'Contabilidade de gestão fiscal',
  'Suporte via chat,  e-mail e telefone',
]

export const PremiumPlanImageCard = ({title, subTitle, imageSrc}) =>
  <div style={{ flex: '0 0 50%',  height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center',
        fontFamily: 'Poppins',
        textAlign: 'center',
        overflow: 'hidden',
        justifyContent: 'space-around', padding: '0 9%', background: '#FFFFFF' }} >
        <img src={`/${imageSrc}`} style={{ maxWidth: '171px', maxHeight: '127px', objectFit: 'contain' }} />
        <div>
          <div style={{
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '32px',
            fontFamily: 'Inter',
          }}>
          {title}
          </div>
          <div style={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
          }}>
          {subTitle}
          </div>
        </div>
        <div style = {{
          width: '100%',
          height: '48px',
          borderRadius: '8px',
          border: '2px',
          background: '#186AFF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#FFFFFF',
          cursor: 'pointer'
        }}>
          Solicitar Proposta
        </div>
      </div>


export const PremiumPlan = ({title, subTitle, imageSrc}) => {
  return (
    <div style={{
      width: '100%',
      aspectRatio: '1092/451',
      /* maxWidth: '1092px',
      maxHeight: '451px', */
      display: 'flex',
      borderRadius: '16px',
      opacity: 1,
      borderBottom: '2px solid rgba(0, 0, 0, 0.7)',
      fontFamily: 'Poppins',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px',
      textAlign: 'center',
      overflow: 'hidden'
     }}>
      <PremiumPlanImageCard title={title} subTitle={subTitle} imageSrc={imageSrc} />
      <div style={{ flex: '0 0 50%', height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column',  justifyContent: 'center', color: '#FFFFFF', background: '#000D23' }}>
          <div style={{ fontSize: 24, fontWeight: 700, marginBottom: 16 }}>Plano Premium</div>
          <div style={{ fontSize: 16, color: '#FFFFFFA6', padding: "48px 24px 48px 24px" }}>
            Administração sem estresse. A Condofy cuida de tudo para você ter tranquilidade no seu condomínio.
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', gap: '16px', justifyItems: 'left' }}>
            {premiumPlanOffers.map((offer) => (

              <div key = {offer} style={{ display: 'flex', alignItems: 'center' }}>
                <Checkmark style={{ height: '24px', width: '24px', marginRight: '16px' }}/>
                <span style={{
                  fontSize: '12px', fontWeight: 400, lineHeight: '24px', color: '#FFFFFF' }}>{offer}</span>
              </div>
            ))}
          </div>
      </div>
    </div>
  );
};
