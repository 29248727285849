import { useContext } from 'react'
import { createStore } from '../core/'
import * as unitType from './unitType'

const subStores = [unitType]

const [UnitTypeContext, UnitTypeProvider] = createStore(subStores)

export const useUnitTypeContext = () => useContext(UnitTypeContext)

export { UnitTypeContext, UnitTypeProvider }
