import { Flex, PageSubTitle } from '@condofy/components'
import React from 'react'
import {
  AccountBalanceList,
  BalanceSheet,
  CashflowChart,
  IncomeVsExpencesChart
} from '../../next/containers/finance'
import { ChartBlock } from './ChartBlock'
import { useUserContext } from '@condofy/next/store/user'

import { PremiumPlanImageCard } from '@condofy/next/components'


export const OverviewTab = () => {
  const {
    state: { currentCondominium }
  } = useUserContext()
  const userIsPremium = !(
    currentCondominium &&
    currentCondominium.plan &&
    currentCondominium.plan.id === '2'
  )

  return (
    <>
      <Flex direction='column' spacingY={3}>
        <PageSubTitle>Saldo de Contas</PageSubTitle>
        <div>
          <AccountBalanceList />
        </div>
        <div>
          <Flex
            itemsGrid={6}
            spacingX={3}
            alignItems='stretch'
            alignContent='stretch'
          >
            <ChartBlock
              title='Total previsto / realizado'
              tooltip='Total previso versus realizado de acordo com o vencimento. Desconsidera transferências'
            >
              {userIsPremium ? <IncomeVsExpencesChart /> : (
                                <div
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover'
                                }}
                                >
                                <PremiumPlanImageCard
                                  title='Transparência nas suas movimentações'
                                  subTitle='Acompanhe despesas e receitas do condomínio. Com o plano premium, acesse relatórios para um gerenciamento mais claro.'
                                  imageSrc='finances.png'
                                />
                                </div>
              )}
            </ChartBlock>
            <ChartBlock
              title='Fluxo de Caixa'
              tooltip='Total das entradas, saídas e saldo considerando o valor efetivo do caixa e desconsiderando transferências'
            >
              <CashflowChart />
            </ChartBlock>
          </Flex>
        </div>
        <div>
          <Flex itemsGrid={true}>
            <BalanceSheet />
          </Flex>
        </div>
      </Flex>
    </>
  )
}
