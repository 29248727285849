import React from 'react'
import { Routes, Route/* , useNavigate */ } from 'react-router-dom';
/* import { useEffect } from 'react'; */
import {
  AdminCondominiumPage,
  AdminPlatformPage,
  FinancesDashboardPage,
  CommunityPage,
  BillingPage,
  BankConsentFinishedPage,
  BelvoConsentFinishedPage,
  TicketsPage,
  CalendarPage,
  FilesPage,
  SurveysPage,
  ComplainsPage,
  MyProfilePage,
  AnnouncementsPage
} from './pages'
import { SchedulePage, NewReservationPage } from '@condofy/next/pages'
import { useUserContext } from '@condofy/next/store/user'
import { DashboardLayout } from './layouts/'

const MyRoutes = () => {
  const {
    state: { isAdmin, isSuperAdmin/* , currentCondominium */ }
  } = useUserContext()

/*   const navigate = useNavigate();
  useEffect(() => {
      navigate("/")
  }, []); */

  return (
      <Routes>
        {isSuperAdmin && (
          <Route path='/platform' element={<DashboardLayout><AdminPlatformPage/></DashboardLayout>} />
        )}
        {(isAdmin || isSuperAdmin) && (
          <Route path='/admin' element={<DashboardLayout><AdminCondominiumPage/></DashboardLayout>} />
        )}
        <Route path='/my-profile' element={<DashboardLayout><MyProfilePage/></DashboardLayout>} />
        <Route
          path='/schedule/reservation/new'
          element={<DashboardLayout><NewReservationPage/></DashboardLayout>}
        />
        <Route path='/schedule' element={<DashboardLayout><SchedulePage/></DashboardLayout>} />
        <Route
          path='/finance'
          element={<DashboardLayout><FinancesDashboardPage/></DashboardLayout>}
        />
        <Route path='/tickets' element={<DashboardLayout><TicketsPage/></DashboardLayout>} />
        <Route path='/calendar' element={<DashboardLayout><CalendarPage/></DashboardLayout>} />
        <Route path='/files' element={<DashboardLayout><FilesPage/></DashboardLayout>} />
        <Route path='/surveys' element={<DashboardLayout><SurveysPage/></DashboardLayout>} />
        <Route path='/complains' element={<DashboardLayout><ComplainsPage/></DashboardLayout>} />
        <Route
          path='/announcements'
          element={<DashboardLayout><AnnouncementsPage/></DashboardLayout>}
        />
        <Route path='/billing' element={<DashboardLayout><BillingPage/></DashboardLayout>} />
        <Route path='/community' element={<DashboardLayout><CommunityPage/></DashboardLayout>} />
        <Route
          path='/bs2-finished'
          element={<BankConsentFinishedPage  bankCode = 'bs2' />}
        />
        <Route
          path='/btgpactual-finished'
          element={<BankConsentFinishedPage  bankCode = 'btgpactual' />}
        />
        <Route
          path='/belvo-finished'
          element={<BelvoConsentFinishedPage/>}
        />
        <Route path='/' element={<DashboardLayout><FinancesDashboardPage/></DashboardLayout>} />
      </Routes>


  )
}

export default MyRoutes
