import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { theme } from '../../styles'

const FlexDiv = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['itemsGrid', 'spacingX', 'spacingXpx', 'spacingY', 'spacingYpx', 'flexWrap'].includes(
      prop
    )
})`
  display: flex;
  flex-wrap: ${({ flexWrap }) => (flexWrap || 'nowrap')};
  width: calc(100% + ${({ spacingXpx }) => `${spacingXpx}`});
  margin: ${({ spacingY, spacingX }) =>
    `${theme.spacing(spacingY / -2)} ${theme.spacing(spacingX / -2)}`};

  & > * {
    margin: ${({ spacingY, spacingX }) =>
      `${theme.spacing(spacingY / 2)} ${theme.spacing(
        spacingX / 2
      )} !important`};
  }
  & > * {
    ${({ itemsGrid }) => {
      if (Number.isInteger(itemsGrid)) {
        const size = 100 * (itemsGrid / 12)

        return {
          'flex-grow': 1,
          'max-width': `${size}%`,
          'flex-basis': `${size}%`
        }
      }
      if (itemsGrid) {
        return {
          'flex-grow': 1,
          'max-width': '100%',
          'flex-basis': '100%'
        }
      } else {
        return {}
      }
    }}
  }
`

export const Flex = ({
  spacing = 0,
  spacingX = 0,
  spacingY = 0,
  itemsGrid = false,
  children,
  ...props
}) => {
  const spacingXpx = theme.spacing(Number(spacingX || spacing))
  const spacingYpx = theme.spacing(Number(spacingY || spacing))

  return (
    <FlexDiv
      spacingX={Number(spacingX || spacing)}
      spacingXpx={spacingXpx}
      spacingY={Number(spacingY || spacing)}
      spacingYpx={spacingYpx}
      flexWrap={props.wrap}
      className={props.className}
      itemsGrid={itemsGrid}
      style={{
        justifyContent: props.justify || 'flex-start',
        flexDirection: props.direction || 'row',
        flexWrap: props.wrap || 'nowrap',
        alignItems: props.alignItems || 'stretch',
        alignContent: props.alignContent || 'stretch',
        width: props.width || 'auto',
        height: props.height || 'auto',
        maxWidth: props.maxWidth || 'none'
      }}
      {...props}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          const { flex, style, ...childProps } = child.props
          const flexProp = flex ? { flex } : {}

          return React.cloneElement(child, {
            style: { ...flexProp, ...style },
            ...childProps
          })
        }
      })}
    </FlexDiv>
  )
}

Flex.propTypes = {
  justify: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly'
  ]),
  direction: PropTypes.oneOf([
    'row',
    'row-reverse',
    'column',
    'column-reverse'
  ]),
  alignItems: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'stretch',
    'baseline'
  ]),
  alignContent: PropTypes.oneOf([
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around'
  ]),
  wrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
  spacing: PropTypes.number,
  spacingX: PropTypes.number,
  spacingY: PropTypes.number,
  itemsGrid: PropTypes.oneOf([
    false,
    true,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12
  ]),
  width: PropTypes.string,
  height: PropTypes.string,
  maxWidth: PropTypes.string
}

Flex.defaultProps = {
  direction: 'row',
  justify: 'flex-start',
  wrap: 'nowrap',
  alignItems: 'stretch',
  alignContent: 'stretch',
  width: 'auto',
  height: 'auto',
  maxWidth: 'none',
  spacing: 0,
  spacingX: 0,
  spacingY: 0,
  gridItems: false
}
