import { unitTypeList } from '@condofy/next/service/unit'
import { List } from '../core/'

const key = 'UNIT_TYPE'

const unitType = new List({
  key: `${key}_LIST`,
  statePropName: 'unit_types'
})

export const initialState = {
  unit_types: { ...unitType.initialState }
}


export const createMiddleware = () => {
  return {
    ...unitType.middleware(unitTypeList),
  }
}

export const useActions = (state, dispatch) => ({
  listUnitTypes: unitType.actions(state, dispatch),
})

export const createReducers = () => {
  return {
    ...unitType.reducers()
  }
}
