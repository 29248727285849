import React from 'react'
import {
  Card,
  CardContent,
  Flex,
  Heading6,
  SmallBody,
  LoadingButton
} from '@condofy/components'
import { Grid } from '@mui/material'

export const IntegrationCard = ({
  ImageComponent,
  title = '',
  description = '',
  loading = false,
  showConnectButton = true,
  showDisconnectButton = false,
  connectButtonText = 'Conectar',
  disconnectButtonText = 'Desconectar',
  AppendageComponent,
  onConnect: emitOnConnect = () => {},
  onDisconnect: emitOnDisconnect = () => {}
}) => {
  return (
    <Card width="calc(50% - 24px)">
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Flex alignItems='center' justify='center' height='100%'>
              {ImageComponent}
            </Flex>
          </Grid>
          <Grid item xs={true} sx={{ flexGrow: 1 }}>
            <Heading6>{title}</Heading6>
            <SmallBody>{description}</SmallBody>
          </Grid>
          <Grid item xs={2.5}  style={{ minWidth: 120 }}>
            <Grid
              container
              justify='center'
              alignItems='center'
              direction='column'
              justifyContent={'center'}
              style={{ height: '100%' }}
            >
              {AppendageComponent}
              {showConnectButton && (
                <Flex alignItems='center' >
                  <LoadingButton loading={loading} onClick={emitOnConnect}>
                    {connectButtonText}
                  </LoadingButton>
                </Flex>
              )}
              {showDisconnectButton && (
                <Flex alignItems='center'>
                  <LoadingButton
                    color='danger'
                    loading={loading}
                    onClick={emitOnDisconnect}
                  >
                    {disconnectButtonText}
                  </LoadingButton>
                </Flex>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
