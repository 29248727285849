/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  FormRow,
  Page,
  PageTitle,
  CardHeader,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  LoadingButton
} from '@condofy/components'
import { EditableInput, EditablePassword } from '@condofy/next/components'
import { useUserContext } from '@condofy/next/store/user'
import { useSnackbar, handleError } from '@condofy/next/hooks/useSnackbar'
import { phoneMask, cpfMask, MyConnections } from '@condofy/next/containers'
import { condofyProtected } from '@condofy/next/service/instance'

export const MyProfilePage = () => {
  const { enqueueSnackbar } = useSnackbar()

  const {
    state: { temporaryProfileData, id, currentCondominium },
    actions
  } = useUserContext()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [confirmText, setConfirmText] = useState('')
  const openDeleteDialog = () => setIsDeleteDialogOpen(true)
  const openCancelDialog = () => setIsCancelDialogOpen(true)
  const closeDeleteDialog = () => setIsDeleteDialogOpen(false)
  const closeCancelDialog = () => setIsCancelDialogOpen(false)

  const deleteAccount = () => {
    setIsLoading(true)
    condofyProtected
      .delete(`/accounts`)
      .then(() => {
        enqueueSnackbar('Conta excluída com sucesso', {
          variant: 'success'
        })
        setIsLoading(false)
        closeDeleteDialog()
        actions.logout()
      })
      .catch(() => {
        setIsLoading(false)
        enqueueSnackbar(
          'Erro ao excluir a conta, por favor tente novamente, ou entre em contato com nossa equipe.',
          {
            variant: 'error'
          }
        )
      })
  }

const cancelSubscription = () => {
    setIsLoading(true)
    condofyProtected
    .delete(`/v1/condominiums/${currentCondominium.id}/subscriptions`)
    .then(() => {
        enqueueSnackbar('Assinatura cancelada com sucesso.', {
          variant: 'success'
        })
        setIsLoading(false)
        closeCancelDialog()
      })
      .catch(() => {
        setIsLoading(false)
        enqueueSnackbar(
          'Erro ao cancelar assinatura, por favor tente novamente, ou entre em contato com nossa equipe.',
          {
            variant: 'error'
          }
        )
      }).finally(() => setIsLoading(false))
  }

  const handlePasswordChange = (value) =>
    actions
      .changePassword(value)
      .then(() => {
        enqueueSnackbar('Senha alterada com sucesso', { variant: 'success' })
      })
      .catch(handleError(enqueueSnackbar))

  const handleNameChange = (value) =>
    actions
      .changeName(value)
      .then(() => {
        enqueueSnackbar('Nome alterado com sucesso', { variant: 'success' })
      })
      .catch(handleError(enqueueSnackbar))

  const handlePhoneChange = (value) => {
    localStorage.setItem('userId', id)
    const newPhone = value.replace(/\D/g, '')
    actions.changePhoneNumber(newPhone).catch(handleError(enqueueSnackbar))
  }

  const handleEmailChange = (value) =>
    actions.changeEmail(value).catch(handleError(enqueueSnackbar))

  return (
    <Page>
      <PageTitle title='Meu perfil' icon='user' />
      <Card>
        <CardContent>
          <FormRow>
            <EditableInput
              label='Nome'
              loading={temporaryProfileData.loading}
              value={temporaryProfileData.name}
              onChange={actions.updateTemporaryName}
              onSubmit={handleNameChange}
            />
          </FormRow>
          <FormRow>
            <EditableInput
              label='Email'
              loading={temporaryProfileData.loading}
              value={temporaryProfileData.email}
              onChange={actions.updateTemporaryEmail}
              onSubmit={handleEmailChange}
            />
          </FormRow>
          <FormRow>
            <EditableInput
              label='Celular'
              loading={temporaryProfileData.loading}
              value={
                temporaryProfileData.phoneNumber
                  ? phoneMask(temporaryProfileData.phoneNumber)
                  : ''
              }
              onChange={actions.updateTemporaryPhoneNumber}
              onSubmit={handlePhoneChange}
            />
          </FormRow>
          <FormRow>
            <EditableInput
              label='CPF'
              editable={false}
              value={
                temporaryProfileData.socialSecurityNumber
                  ? cpfMask(temporaryProfileData.socialSecurityNumber)
                  : ''
              }
              onChange={actions.updateTemporarySocialSecurityNumber}
            />
          </FormRow>
          <FormRow>
            <EditablePassword
              label='Senha'
              loading={temporaryProfileData.loading}
              onSubmit={handlePasswordChange}
            />
          </FormRow>
        </CardContent>
      </Card>

      {currentCondominium.plan.name === "Starter" &&(
        <Card
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingRight: '24px',
            alignItems: 'center'
          }}
        >
          <CardHeader title='Cancelar assinatura' />
          <Button color='danger' onClick={openCancelDialog}>
            Cancelar
          </Button>
        </Card>
      )}

      <Card>
        <CardHeader title='Minhas conexões' />
        <CardContent>
          <MyConnections />
        </CardContent>
      </Card>
      <Card
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingRight: '24px',
          alignItems: 'center'
        }}
      >
        <CardHeader title='Excluir conta' />
        <Button color='danger' onClick={openDeleteDialog}>
          Excluir
        </Button>
      </Card>
      <Dialog
        open={isCancelDialogOpen}
        title='Aviso'
        onClose={closeCancelDialog}
      >
        <DialogContent>
          <p>
            Olá, {temporaryProfileData.name}
            <br />
            Ao prosseguir com o cancelamento, todos os acessos de síndico e dos condomínios se tornarão inativos.
            Sua assinatura ainda permanecerá válida até o final do ciclo de faturamento.
          </p>
          <p>
            Tem certeza que deseja continuar com o cancelamento de sua assinatura?
            <br />
            Se sim, escreva{' '}
            <Typography color='error'>CANCELAR</Typography> no campo abaixo.
          </p>
          <p>
            <TextField
              label=''
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
            />
          </p>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={closeCancelDialog}>
            Cancelar
          </Button>
          <LoadingButton
            loading={isLoading}
            onClick={cancelSubscription}
            disabled={isLoading || !confirmText || confirmText !== 'CANCELAR'}
          >
            Confirmar
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDeleteDialogOpen}
        title='Aviso'
        onClose={closeDeleteDialog}
      >
        <DialogContent>
          <p>
            Você está prestes a excluir seu cadastro. Uma vez feito, seus dados
            serão deletados e o acesso à plataforma Condofy será revogado.
          </p>
          <p>
            Para continuar, digite a palavra{' '}
            <Typography color='error'>EXCLUIR </Typography> no campo abaixo.
          </p>
          <p>
            <TextField
              label=''
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
            />
          </p>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={closeDeleteDialog}>
            Cancelar
          </Button>
          <LoadingButton
            loading={isLoading}
            onClick={deleteAccount}
            disabled={isLoading || !confirmText || confirmText !== 'EXCLUIR'}
          >
            Confirmar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Page>
  )
}
