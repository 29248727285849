import { condofyProtected } from './instance'

export const restrictedUnitListPromise = ({ currentCondominium }) =>
  condofyProtected.get(
    `/v1/condominiums/${currentCondominium.id}/scheduling-restriction/unit`
  )

export const fetchRestrictedUnitList = ({
  currentCondominium,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  restrictedUnitListPromise({ currentCondominium })
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error))
}

export const createUnit = ({ currentCondominium, unit }) =>
  condofyProtected.post(`/v1/condominiums/${currentCondominium.id}/units`, {
    ...unit,
    typeId: unit.type.id
  })

export const editUnit = ({ currentCondominium, unit }) =>
  condofyProtected.put(
    `/v1/condominiums/${currentCondominium.id}/units/${unit.id}`,
    { number: unit.number, block: unit.block, observations: unit.observations, typeId: unit?.type?.id || 99 }
  )


export const fullList = ({ currentCondominium, includeUsers }) =>
  condofyProtected.get(
    `/v1/condominiums/${currentCondominium.id}/units/list?includeUsers=true`,
    {
      params: {
        includeUsers
      }
    }
  )

export const unitTypeList = () =>
  condofyProtected.get(
    `/v1/unit-types`
  )

export const search = ({
  currentCondominium,
  PageNumber = 1,
  generalSearch = '',
  RecordsPerPage,
  includeUsers = false
}) => {
  return condofyProtected.get(
    `/v1/condominiums/${currentCondominium.id}/units`,
    {
      params: {
        PageNumber,
        GeneralSearch: generalSearch,
        RecordsPerPage,
        includeUsers
      }
    }
  )
}
