import React from 'react'
import { Page, PageTitle } from '@condofy/components'
import { BillingList } from '@condofy/next/containers/billing'
import { PremiumPlan, PremiumToggle } from '@condofy/next/components'

const StarterContent = () => (
  <PremiumPlan
    title='Suas faturas em um só lugar'
    subTitle='Veja todas as faturas na plataforma Condofy. Com o plano premium, gere segundas vias rapidamente e mantenha tudo seguro.'
    imageSrc='billing.png'
  />
)

//todo

export const BillingPage = () => (
  <Page>
    <PremiumToggle fallback={<StarterContent />}>
      <PageTitle title='Faturas' icon='billing' />
      <BillingList controls />
    </PremiumToggle>
  </Page>
)
