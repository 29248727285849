import React from 'react'
import { Page, PageTitle } from '@condofy/components'
import { PremiumPlan, PremiumToggle } from '@condofy/next/components'
import { TicketList } from '@condofy/next/containers/ticket'

const StarterContent = () => (
  <PremiumPlan
    title='Ajuda rápida quando você precisa'
    subTitle='Precisa de ajuda? Com o plano premium, abra um chamado e fale com nosso suporte para resolver problemas rapidamente. Conte com nosso atendimento especializado.'
    imageSrc='tickets.png'
  />
)

export const TicketsPage = () => (
  <Page>
    <PremiumToggle fallback={<StarterContent />}>
      <PageTitle title='Chamados' icon='tickets' />
      <TicketList />
    </PremiumToggle>
  </Page>
)
