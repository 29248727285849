import React from 'react'
import { Flex, PageSubTitle } from '@condofy/components'

import ImgBs2 from '~/assets/banco-bs2-logo.svg'
import LogoBTG from '~/assets/BTG-logo.svg'


import {
  BankIntegration,
  BanksIntegrationsCard
} from '@condofy/next/containers/'

export const IntegrationTab = () => {
  return (
    <>
      <Flex direction='column' spacingY={3}>
        <PageSubTitle>Integrações bancárias</PageSubTitle>
      </Flex>

      <Flex direction='row' wrap='wrap' spacingY={3} spacingX={3}>

      <BankIntegration
        LogoComponent={ImgBs2}
        title='Banco BS2'
        bankCode='bs2'
        description = {`Tenha sua conta totalmente integrada com nossa plataforma digital para transferências, pagamentos, emissão de boletos e muito mais`}
        cancelDialogText={`Ao desconectar a integração BS2 a Condofy perderá toda a conexão com sua conta corrente e não poderá mais realizar nenhuma transação.`}

      />
      <BankIntegration
        LogoComponent={LogoBTG}
        title='BTG Pactual'
        bankCode='btgpactual'
        description = {`Tenha sua conta totalmente integrada com nossa plataforma digital para transferências, pagamentos, emissão de boletos e muito mais`}
        cancelDialogText={`Ao desconectar a integração BTG a Condofy perderá toda a conexão com sua conta corrente e não poderá mais realizar nenhuma transação.`}
      />
      <BanksIntegrationsCard/>
      </Flex>
    </>
  )
}

