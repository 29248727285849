import React from 'react'
import {
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  CardMedia,
  CardActions as MuiCardActions
} from '@mui/material'
import styled from 'styled-components'
import { typography, colors, theme, shadows } from '../../styles'
import PropTypes from 'prop-types'

const StyledCard = styled(MuiCard)`
  &.MuiCard-root {
    border: solid 1px ${colors.light400};
    border-radius: 4px;
    background-color: ${colors.light100};
    box-shadow: ${shadows[1]};
    display: flex;
    flex-direction: column;
    outline: none;
  }

  & .MuiCardHeader-root + .MuiCardContent-root {
    padding-top: 0;
  }
`

export const Card = ({ marginBottom = 3, width, ...props }) => {
  const style = {...(width ? { width } : {}), marginBottom: theme.spacing(marginBottom)}
  return <StyledCard
    // style={{ marginBottom: theme.spacing(marginBottom),  }}
    style={style}
    {...props}
  />
}

Card.propTypes = {
  marginBottom: PropTypes.number
}

export const CardHeader = styled(MuiCardHeader)`
  &.MuiCardHeader-root {
    min-height: 56px;
    padding: ${theme.spacing(3)};
  }

  & .MuiCardHeader-title {
    color: ${colors.dark300};
    font-size: ${typography.size.h5}px;
    font-weight: 600;
    font-family: ${typography.type.secondary};
  }

  & .MuiCardHeader-action {
    margin: 0;
    display: flex;
    align-items: center;

    & .MuiButton-root {
      font-size: 14px;
      font-weight: 700;
    }

    & > * {
      margin-left: ${theme.spacing(3)};
    }
  }
`

export const CardActions = styled(MuiCardActions)`
  padding: 0 ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)};
`

export { CardMedia }
