import React, { useEffect, useState } from 'react'
import { DataTable, DialogContent } from '@condofy/components'
import { FormSectionHeader, FormSectionTitle } from '@condofy/components'
import { Modal, ModalSection } from '@condofy/next/components'
import { UnitUserList } from '../../unit'

export const DetailsUnitModal = ({ close, options }) => {
  const [unit, setUnit] = useState([])

  useEffect(() => {
    setUnit([options.unit])
  }, [])

  const columns = [
    { field: 'block', headerName: 'Bloco' },
    { field: 'number', headerName: 'Unidade' },
    {
      field: 'type',
      headerName: 'Tipo de unidade',
      valueGetter: (params) => params?.row?.type?.name ?? 'Não informado'
    },
    { field: 'observations', headerName: 'Observação' }
  ]

  return (
    <Modal
      title={`Dados da Unidade ${options.unit.number}`}
      open
      onClose={close}
    >
      <DialogContent>
        <FormSectionTitle>Unidade</FormSectionTitle>
        <DataTable
          variant='borderless'
          size='small'
          columns={columns}
          rows={unit || []}
        />
      </DialogContent>
      {Boolean(options.unit.id) && (
        <DialogContent>
          {Boolean(options.unit.users?.length) && (
            <ModalSection border={1}>
              <FormSectionHeader>
                <FormSectionTitle>Moradores</FormSectionTitle>
              </FormSectionHeader>
              <UnitUserList
                users={options.unit.users}
                unit={options.unit}
                condominium={options.condominium}
              />
            </ModalSection>
          )}
        </DialogContent>
      )}
    </Modal>
  )
}
